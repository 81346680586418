import {useRouter} from "next/router";
import {useCallback} from "react";
import {logErrorRx} from "./logError";
import {BehaviorSubject, catchError, firstValueFrom, map, mergeMap, of, zip} from "rxjs";
import {PaymentService} from "../services/paymentService";
import {CurrencyUtils} from "./currencyUtils";
import {useAppDispatch} from "../app/hooks";
import {setCartMenuOpen, showAlert} from "../redux/navigation/navigationSlice";
import {useModalRouting} from "./useModalRouting";
import {loginWithApple, loginWithFacebook} from "../redux/auth/authSlice";
import {StoreService} from "../services/storeService";
import {getStore} from "../app/store";
import {ShoppingCartItemModel} from "../models/shoppingCartItem";
import { isMobile } from 'react-device-detect';
import {UserService} from "../services/userService";

export const useDeeplinkRouter = () => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const { createRoute } = useModalRouting()

  const isHandlingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  const handlePromoCode = (promoCode: string, storeId?: string) => {
    PaymentService.verifyPromoCode(promoCode, storeId)
      .subscribe({
        error: (e) => {

        },
        next: (promoCode) => {
          PaymentService.setPromoCode(promoCode.id)
          let title = 'You\'ve Got';
          const message = 'Your discount will be applied at checkout';

          promoCode.discounts.forEach((discount, index) => {
            if (discount.amountOff) {
              title += ` ${CurrencyUtils.stringFromPrice(discount.amountOff)} Off`;
            } else if (discount.percentOff) {
              title += ` ${discount.percentOff * 100}% Off`;
            }

            if (discount.type === 'product' && discount.products.length) {
              title += ' Select Products';
            } else if (discount.type === 'shipping') {
              title += ' Shipping';
            }

            if (index === promoCode.discounts.length - 2) {
              title += ' &';
            } else if (index < promoCode.discounts.length - 2) {
              title += ',';
            }
          });

          title += ' 🎉';

          dispatch(showAlert({ title, message }))
        }
      })
  }

  const handleMenuItemIds = (menuItems: { id: string, quantity: number }[], storeId: string) => {
    const itemObservables = menuItems.map(({ id, quantity }) => {
      return StoreService.fetchProduct(id)
        .pipe(
          map(product => {
            product.updateWithQuantity(quantity)
            const item = product.toLineItem()
            if (!item) return undefined
            return item
          })
        )
    })

    return new Promise((res, rej) => {
      zip(itemObservables)
        .pipe(
          mergeMap(items => {
            const localCart = getStore()?.getState().cart.cartMap[storeId]
            return StoreService.addItemsToCart(items.filter(i => i).map(i => i as ShoppingCartItemModel), storeId, localCart)
          }),
          catchError(() => of(null))
        )
        .subscribe({
          next: v => {
            res(v)
          },
          error: e => {
            rej(e)
          }
        })
    })
  }

  const handleReferrer = (referrer: string) => {
    UserService.updateAppReferrer(referrer)
      .subscribe({
        error: (e) => {

        },
        next: () => {

        }
      })
  }

  const handleDeeplinkUrl = async (url: URL, branchData?: any): Promise<boolean> => {
    const scheme = url.protocol + '//'
    const path = url.pathname.replace('//', '/') || `/${url.host}`

    if (scheme !== 'fitgenie://' && url.host !== 'getfitgenie.com') {
      return false
    }

    let promo_code: string | undefined | null
    let store_id: string | undefined | null
    let menu_item_id: string | undefined | null
    let category_id: string | undefined | null
    let section_id: string | undefined | null

    store_id = url.searchParams.get('store_id') ?? url.searchParams.get('primary_store_id') ?? undefined
    promo_code = url.searchParams.get('promo_code')

    const referrer = branchData?.referrer
    referrer && handleReferrer(referrer)

    let didDeeplink = true;
    switch (path) {
      case "/store_feed":
        await router.push({ pathname: '/store-feed', query: promo_code && { promo_code } })
        break;
      case "/store":
        if (store_id) {
          await router.push({ pathname: `/store/${store_id}`, query: promo_code && { promo_code } })
        } else {
          await router.push({ pathname: '/store-feed', query: promo_code && { promo_code } })
        }

        break;
      case "/store_detail":
        category_id = url.searchParams.get('category_id') ?? undefined
        section_id = url.searchParams.get('section_id') ?? undefined

        if (store_id) {
          await router.push({ pathname: `/store/${store_id}`, query: promo_code && { promo_code } })
        }

        if (category_id) {
          await router.push({ pathname: `/store-category/${category_id}` })
        } else if (section_id) {
          await router.push({ pathname: `/store-section/${section_id}` })
        }

        break;
      case "/menu_item_detail":
        menu_item_id = url.searchParams.get('menu_item_id') ?? undefined

        if (store_id) {
          const query: any = { product_id: menu_item_id }
          if (promo_code) {
            query.promo_code = promo_code
          }
          await router.push(
            createRoute({ rootPath: `/store/${store_id}`, name: 'product_detail', query })
          )
        } else {
          await router.push({ pathname: `/product/${menu_item_id}`, query: promo_code && { promo_code } })
        }

        break;
      case "/upgrade":
        const code = url.searchParams.get('code')
        await router.push({ pathname: '/upgrade', query: code && { code } })
      case "/cart":
        const items = url.searchParams.get('menu_items')?.split(',') ?? []
        const menuItems = items.map(item => {
          const itemInfo = item.split('|')
          const id = itemInfo[0]
          const quantity = itemInfo[itemInfo.length - 1] ?? '1'

          if (id) {
            return { id, quantity: parseInt(quantity) }
          } else {
            return null
          }
        }).flatMap(i => i ? [i] : [])

        menuItems.length && store_id && await handleMenuItemIds(menuItems, store_id)

        if (store_id) {
          if (isMobile) {
            await router.push(
              createRoute({ name: 'cart', rootPath: `/store/${store_id}`, query: { cart_id: store_id } }),
              `/cart/${store_id}`,
              { shallow: true }
            )
          } else {
            await router.push({ pathname: `/store/${store_id}`, query: promo_code && { promo_code } })
            dispatch(setCartMenuOpen(true))
          }
        }

        break;
      case "/add_card":
        await router.push(
          createRoute({ name: 'payment_entry' }),
          undefined,
          { shallow: true }
        );
        break;
      case "/card_list":
        await router.push(
          createRoute({ name: 'payment_selector' }),
          undefined,
          { shallow: true }
        );
        break;
      case "/download":
        await router.push({ pathname: '/download' })
        break;
      case "/facebook_login":
        dispatch(loginWithFacebook())
        break;
      case "/apple_login":
        dispatch(loginWithApple())
        break;
      default:
        didDeeplink = false;
        break;
    }

    promo_code && handlePromoCode(promo_code, store_id);
    return didDeeplink;
  }

  const initSession = useCallback(() => {
    async function initAndFetch() {
      const BranchSDK = (await import('branch-sdk')).default
      BranchSDK.init(process.env.NEXT_PUBLIC_BRANCH_KEY!)

      BranchSDK.data(function (err, data) {
        console.log(data)
        // if (process.env.NODE_ENV !== 'production') console.log(data)

        if (err) {
          firstValueFrom(logErrorRx(err))
            .then(() => {})
            .catch(() => {})
          return
        } else if (data) {
          const deeplink = (data.data_parsed as any)['$deeplink_path'] ?? (data.data_parsed as any)['~referring_link']
          if (!deeplink) return;

          const url = new URL(deeplink)

          isHandlingSubject.next(true);

          handleDeeplinkUrl(url, data.data_parsed as any)
            .finally(() => {
              isHandlingSubject.next(false);
            })
        }
      })

      return BranchSDK;
    }

    return initAndFetch()
  }, [])

  return { initSession, handleDeeplinkUrl, isHandlingSubject }
}