import {RootEpic} from "../../app/store";
import {asyncScheduler, catchError, filter, map, mergeMap, of, scheduled, throwError} from "rxjs";
import {
  getFitnessProfileFromAnswers,
  MacroSurveyAnswers,
  MacroSurveyQuestion,
  setActivityLevel,
  setBodyType,
  setCurrentQuestion,
  setDeadlineMonths,
  setDietaryPreference,
  setDietGoal,
  setGender,
  setPhysicalDetails,
  submitFailed,
  submitSurvey,
  submitSurveySucceeded,
  validationFailed
} from "./macroSurveySlice";
import {configureLoggedInUser, onPostLogin, validate as validateLogin} from "../auth/authEpics"
import {MacroSurveyValidationError} from "../../errors/macroSurveyValidationError";
import {logErrorRx} from "../../utils/logError";
import {DietGoal, HeightUnit} from "../../models/fitnessProfile";
import {push} from "connected-next-router";
import {NutritionProtocolService} from "../../services/nutritionProtocolService";
import {
  loginWithAppleAndSubmitSurvey, loginWithAppleSucceeded,
  loginWithEmailAndSubmitSurvey,
  loginWithEmailSucceeded, loginWithFacebookAndSubmitSurvey, loginWithFacebookSucceeded,
  signUpWithEmailAndSubmitSurvey, signUpWithEmailSucceeded
} from "../auth/authSlice";
import {AuthService} from "../../services/authService";
import {fetchUserSucceeded} from "../user/userSlice";

export const setAnswerEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => {
      return setDietGoal.match(action)
        || setGender.match(action)
        || setDeadlineMonths.match(action)
        || setBodyType.match(action)
        || setActivityLevel.match(action)
        || setPhysicalDetails.match(action)
        || setDietaryPreference.match(action)
    }),
    mergeMap(({ payload }) => {
      const { currentQuestion, remainingQuestions, surveyAnswers } = state$.value.macroSurvey

      return validate(surveyAnswers, currentQuestion, remainingQuestions)
        .pipe(
          mergeMap(() => {
            const loginStatus = state$.value.auth.loginStatus
            const state = state$.value.macroSurvey
            const currentQuestion = state.currentQuestion
            const answers = state.surveyAnswers
            const remainingQuestions = state.remainingQuestions

            let newCurrentQuestion: MacroSurveyQuestion
            if (currentQuestion === 'diet_goal' && answers.goal === DietGoal.maintenance) {
              newCurrentQuestion = remainingQuestions.filter(q => q !== currentQuestion && q !== 'deadline' && q !== 'goal_weight')[0]
            } else {
              newCurrentQuestion = remainingQuestions.filter(q => q !== currentQuestion)[0]
            }

            if (!newCurrentQuestion && loginStatus === 'logged_in') {
              return scheduled([
                submitSurvey()
              ], asyncScheduler)
            } else if (!newCurrentQuestion) {
              return scheduled([
                push({ pathname: '/macro-survey-login' })
              ], asyncScheduler)
            } else {
              return scheduled([
                setCurrentQuestion(newCurrentQuestion),
                push({ pathname: '/macro-survey', query: { question: newCurrentQuestion } })
              ], asyncScheduler)
            }
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: validationFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const signUpWithEmailAndSubmitSurveyEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(signUpWithEmailAndSubmitSurvey.match),
    mergeMap(action => {
      const { currentQuestion, remainingQuestions, surveyAnswers } = state$.value.macroSurvey

      return validate(surveyAnswers, currentQuestion, remainingQuestions)
        .pipe(
          mergeMap(() => validateLogin(action.payload)),
          mergeMap(payload => {
            return AuthService.signUp(payload)
          }),
          mergeMap(authResponse => {
            return configureLoggedInUser(authResponse, false)
              .pipe(map(result => ({ user: result.user, location: result.location, authResponse })))
          }),
          mergeMap(({ user, location, authResponse }) => {
            const fitnessProfile = getFitnessProfileFromAnswers(surveyAnswers)
            return NutritionProtocolService.saveNutritionSurvey(fitnessProfile)
              .pipe(
                map(() => ({ user, location, authResponse }))
              )
          }),
          mergeMap(({ user, location, authResponse }) => {
            const actions: {payload: any, type: string}[] = [
              signUpWithEmailSucceeded({ auth: authResponse, user }),
              fetchUserSucceeded(user),
              submitSurveySucceeded(),
              push({ pathname: '/macro-plan' })
            ]

            const dietaryPreference = state$.value.macroSurvey.surveyAnswers.dietaryPreference

            onPostLogin(authResponse, 'Email', user)

            return scheduled(actions, asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: submitFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const loginWithEmailAndSubmitSurveyEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(loginWithEmailAndSubmitSurvey.match),
    mergeMap(action => {
      const { currentQuestion, remainingQuestions, surveyAnswers } = state$.value.macroSurvey

      return validate(surveyAnswers, currentQuestion, remainingQuestions)
        .pipe(
          mergeMap(() => validateLogin(action.payload)),
          mergeMap(payload => {
            return AuthService.loginWithEmail(payload)
          }),
          mergeMap(authResponse => {
            return configureLoggedInUser(authResponse, false)
              .pipe(map(result => ({ user: result.user, location: result.location, authResponse })))
          }),
          mergeMap(({ user, location, authResponse }) => {
            const fitnessProfile = getFitnessProfileFromAnswers(surveyAnswers)
            return NutritionProtocolService.saveNutritionSurvey(fitnessProfile)
              .pipe(
                map(() => ({ user, location, authResponse }))
              )
          }),
          mergeMap(({ user, location, authResponse }) => {
            const actions: {payload: any, type: string}[] = [
              loginWithEmailSucceeded({ auth: authResponse, user }),
              fetchUserSucceeded(user),
              submitSurveySucceeded(),
              push({ pathname: '/macro-plan' })
            ]

            const dietaryPreference = state$.value.macroSurvey.surveyAnswers.dietaryPreference

            onPostLogin(authResponse, 'Email', user)

            return scheduled(actions, asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: submitFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const loginWithFacebookAndSubmitSurveyEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(loginWithFacebookAndSubmitSurvey.match),
    mergeMap(action => {
      const { currentQuestion, remainingQuestions, surveyAnswers } = state$.value.macroSurvey

      return validate(surveyAnswers, currentQuestion, remainingQuestions)
        .pipe(
          mergeMap(() => {
            return AuthService.loginWithFacebook()
          }),
          mergeMap(authResponse => {
            return configureLoggedInUser(authResponse, false)
              .pipe(map(result => ({ user: result.user, location: result.location, authResponse })))
          }),
          mergeMap(({ user, location, authResponse }) => {
            const fitnessProfile = getFitnessProfileFromAnswers(surveyAnswers)
            return NutritionProtocolService.saveNutritionSurvey(fitnessProfile)
              .pipe(
                map(() => ({ user, location, authResponse }))
              )
          }),
          mergeMap(({ user, location, authResponse }) => {
            const actions: {payload: any, type: string}[] = [
              loginWithFacebookSucceeded({ auth: authResponse, user }),
              fetchUserSucceeded(user),
              submitSurveySucceeded(),
              push({ pathname: '/macro-plan' })
            ]

            const dietaryPreference = state$.value.macroSurvey.surveyAnswers.dietaryPreference

            onPostLogin(authResponse, 'Email', user)

            return scheduled(actions, asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: submitFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const loginWithAppleAndSubmitSurveyEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(loginWithAppleAndSubmitSurvey.match),
    mergeMap(action => {
      const { currentQuestion, remainingQuestions, surveyAnswers } = state$.value.macroSurvey

      return validate(surveyAnswers, currentQuestion, remainingQuestions)
        .pipe(
          mergeMap(() => {
            return AuthService.loginWithApple()
          }),
          mergeMap(authResponse => {
            return configureLoggedInUser(authResponse, false)
              .pipe(map(result => ({ user: result.user, location: result.location, authResponse })))
          }),
          mergeMap(({ user, location, authResponse }) => {
            const fitnessProfile = getFitnessProfileFromAnswers(surveyAnswers)
            return NutritionProtocolService.saveNutritionSurvey(fitnessProfile)
              .pipe(
                map(() => ({ user, location, authResponse }))
              )
          }),
          mergeMap(({ user, location, authResponse }) => {
            const actions: {payload: any, type: string}[] = [
              loginWithAppleSucceeded({ auth: authResponse, user }),
              fetchUserSucceeded(user),
              submitSurveySucceeded(),
              push({ pathname: '/macro-plan' })
            ]

            const dietaryPreference = state$.value.macroSurvey.surveyAnswers.dietaryPreference

            onPostLogin(authResponse, 'Email', user)

            return scheduled(actions, asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: submitFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const submitSurveyEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(submitSurvey.match),
    mergeMap(({ payload }) => {
      const { currentQuestion, remainingQuestions, surveyAnswers } = state$.value.macroSurvey

      return validate(surveyAnswers, currentQuestion, remainingQuestions)
        .pipe(
          mergeMap(() => {
            const fitnessProfile = getFitnessProfileFromAnswers(surveyAnswers)
            return NutritionProtocolService.saveNutritionSurvey(fitnessProfile)
          }),
          mergeMap(() => {
            return scheduled([
              submitSurveySucceeded(),
              push({ pathname: '/macro-plan' })
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: submitFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

const validate = (payload: MacroSurveyAnswers, currentQuestion: MacroSurveyQuestion, remainingQuestions: MacroSurveyQuestion[]) => {
  const heightFeet = payload.heightFeet
  const heightInches = payload.heightInches
  const heightCentimeters = payload.heightCentimeters
  const heightUnit = payload.heightUnit
  const goalWeightString = payload.goalWeight

  let feet = heightFeet ? parseFloat(heightFeet) : undefined
  feet = feet && isNaN(feet) ? undefined : feet
  let inches = heightInches ? parseFloat(heightInches) : undefined
  inches = inches && isNaN(inches) ? undefined : inches
  let centimeters = heightCentimeters ? parseFloat(heightCentimeters) : undefined
  centimeters = centimeters && isNaN(centimeters) ? undefined : centimeters

  let goalWeight = goalWeightString ? parseFloat(goalWeightString) : undefined
  goalWeight = goalWeight && isNaN(goalWeight) ? undefined : goalWeight

  const isLastQuestion = !remainingQuestions.length

  if (!payload.goal && (isLastQuestion || currentQuestion === 'diet_goal')) {
    return throwError(() => new MacroSurveyValidationError("MISSING_DIET_GOAL"))
  } else if (!payload.deadlineMonths && (isLastQuestion || currentQuestion === 'deadline')) {
    return throwError(() => new MacroSurveyValidationError("MISSING_DEADLINE"))
  } else if (payload.deadlineMonths && (payload.deadlineMonths < 1 || payload.deadlineMonths > 5)  && (isLastQuestion || currentQuestion === 'diet_goal')) {
    return throwError(() => new MacroSurveyValidationError("INVALID_DEADLINE"))
  } else if (!payload.bodyType && (isLastQuestion || currentQuestion === 'body_type')) {
    return throwError(() => new MacroSurveyValidationError("MISSING_BODY_TYPE"))
  } else if (!payload.activityLevel && (isLastQuestion || currentQuestion === 'activity_level')) {
    return throwError(() => new MacroSurveyValidationError("MISSING_ACTIVITY_LEVEL"))
  } else if (!payload.gender && (isLastQuestion || currentQuestion === 'gender')) {
    return throwError(() => new MacroSurveyValidationError("MISSING_GENDER"))
  } else if (!payload.startingWeight && (isLastQuestion || currentQuestion === 'physical_details')) {
    return throwError(() => new MacroSurveyValidationError("MISSING_STARTING_WEIGHT"))
  } else if (payload.startingWeight && isNaN(parseInt(payload.startingWeight)) && (isLastQuestion || currentQuestion === 'physical_details')) {
    return throwError(() => new MacroSurveyValidationError("INVALID_STARTING_WEIGHT"))
  } else if (payload.startingWeight && parseInt(payload.startingWeight) < 0 && (isLastQuestion || currentQuestion === 'physical_details')) {
    return throwError(() => new MacroSurveyValidationError("INVALID_STARTING_WEIGHT"))
  } else if (!heightFeet && heightUnit === HeightUnit.imperial && (isLastQuestion || currentQuestion === 'physical_details')) {
    return throwError(() => new MacroSurveyValidationError("MISSING_HEIGHT_FEET"))
  } else if (heightInches == null && heightUnit === HeightUnit.imperial && (isLastQuestion || currentQuestion === 'physical_details')) {
    return throwError(() => new MacroSurveyValidationError("MISSING_HEIGHT_INCHES"))
  } else if ((!feet || inches == null) && heightUnit === HeightUnit.imperial && (isLastQuestion || currentQuestion === 'physical_details')) {
    return throwError(() => new MacroSurveyValidationError("INVALID_HEIGHT"))
  } else if ((feet && inches != null) && (feet > 8 || inches > 11) && heightUnit === HeightUnit.imperial && (isLastQuestion || currentQuestion === 'physical_details')) {
    return throwError(() => new MacroSurveyValidationError("INVALID_HEIGHT"))
  } else if (!heightCentimeters && heightUnit === HeightUnit.metric && (isLastQuestion || currentQuestion === 'physical_details')) {
    return throwError(() => new MacroSurveyValidationError("MISSING_HEIGHT_CENTIMETERS"))
  } else if (!centimeters && heightUnit === HeightUnit.metric && (isLastQuestion || currentQuestion === 'physical_details')) {
    return throwError(() => new MacroSurveyValidationError("INVALID_HEIGHT"))
  } else if (centimeters && centimeters > 245 && heightUnit === HeightUnit.metric && (isLastQuestion || currentQuestion === 'physical_details')) {
    return throwError(() => new MacroSurveyValidationError("INVALID_HEIGHT"))
  } else if (!goalWeight && (isLastQuestion || currentQuestion === 'physical_details')) {
    return throwError(() => new MacroSurveyValidationError("MISSING_GOAL_WEIGHT"))
  } else if (!payload.dietaryPreference && (isLastQuestion || currentQuestion === 'dietary_preference')) {
    return throwError(() => new MacroSurveyValidationError("MISSING_DIET_PREFERENCE"))
  } else {
    return of(payload)
  }
}