import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';
import '../styles/globals.css'
import 'swiper/css'
import "swiper/css/navigation"
import "swiper/css/grid"
import 'react-loading-skeleton/dist/skeleton.css'
import type {AppProps} from 'next/app'
import {appWithTranslation, useTranslation} from "next-i18next";
import Head from "next/head";
// @ts-ignore
import {PersistGate} from 'redux-persist/integration/react'
import React, {useEffect} from "react";
import {ConnectedRouter} from "connected-next-router";
import {useRouter} from "next/router";
import {AppProvider} from "../components/app/AppProvider";
import {SideNav} from "../components/sideNav/SideNav";
import {NavBarMain} from "../components/navBars/NavBarMain";
import {useStore} from "react-redux";
import {wrapper} from "../app/store";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import Script from "next/script";
import {ObjectId} from "bson";
import {useDeeplinkRouter} from "../utils/useDeeplinkRouter";
import {DeeplinkService} from "../services/deeplinkService";
import {MetaTag, MetaTagProperty} from "../models/metaTag";
import OneSignal from 'react-onesignal';
import mixpanel from 'mixpanel-browser';
import {EventLogger} from "../utils/eventLogger";
import {logError} from "../utils/logError";

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY!,
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')!
  .createErrorBoundary(React)

function FitGenieApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const store = useStore()
  const deepLinkRouter = useDeeplinkRouter()

  const { i18n } = useTranslation()

  useEffect(() => {
    OneSignal.init({ appId: process.env.NEXT_PUBLIC_ONE_SIGNAL_APP_ID! })
      .then(() => {})
      .catch((e) => logError(e))
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN!, { debug: false });

    const url = new URL(document.URL)
    EventLogger.updateGlobalPropertiesWithCampaignUrl(url)
  }, [])

  useEffect(() => {
    deepLinkRouter.initSession().then(sdk => {
      DeeplinkService.setBranchSdk(sdk);
    })
  }, [deepLinkRouter])

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(process.env.NEXT_PUBLIC_FB_PIXEL_ID!)
        EventLogger.setReactPixelSdk(ReactPixel)

        ReactPixel.pageView()

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView()
        })
      })
  }, [router.events])

  const AppBody = () => {
    if (router.route === '/' || router.route === '/upgrade') {
      return (
        <AppProvider>
          <SideNav />
          <Component {...pageProps} />
        </AppProvider>
      )
    } else {
      return (
        <AppProvider>
          <SideNav />
          <NavBarMain />
          <Component {...pageProps} />
        </AppProvider>
      )
    }
  }

  const metaTags: MetaTag[] = (pageProps?.metaTags as MetaTag[] | undefined) ?? []
  const title: MetaTag | undefined = metaTags.filter(tag => tag.property === 'og:title')[0]
  const description: MetaTag | undefined = metaTags.filter(tag => tag.property === 'og:description')[0]

  const containsMetaTag = (property: MetaTagProperty) => {
    return metaTags.map(t => t.property).includes(property)
  }

  return (
    <>
      <Head>
        <title>{title?.content ?? 'FitGenie | Healthy Meal Delivery'}</title>
        <meta name="facebook-domain-verification" content="nrofzocqb7acsn7jvz1z2rlfxn6c9h" />
        {
          !containsMetaTag('description') && <meta name="description"
               content={description?.content ?? "Mouth-watering, macro-focused meals. Hand-crafted by the best chefs in your community."}/>
        }
        <meta property="twitter:site" content={process.env.NEXT_PUBLIC_FITGENIE_TWITTER_HANDLE} />
        <meta property="twitter:creator" content={process.env.NEXT_PUBLIC_FITGENIE_TWITTER_HANDLE} />
        <meta property="fb:app_id" content={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="FitGenie" />
        {!containsMetaTag('og:image') && <meta property="og:image" content={process.env.FITGENIE_LANDING_IMAGE_META}/>}
        {!containsMetaTag('twitter:image') && <meta property="twitter:image" content={process.env.FITGENIE_LANDING_IMAGE_META}/>}
        {metaTags.map(tag => {
          return <meta property={tag.property} content={tag.content ?? undefined} key={tag.key ?? tag.property} />
        })}
        <link rel="icon" href="/images/favicon.ico" />
        <link rel="preload" href="/fonts/Gilroy-Medium.eot" as="font" crossOrigin=""/>
        <link rel="preload" href="/fonts/Gilroy-Medium.eot?#iefix" as="font" crossOrigin=""/>
        <link rel="preload" href="/fonts/Gilroy-Medium.woff" as="font" crossOrigin=""/>
        <link rel="preload" href="/fonts/Gilroy-Medium.ttf" as="font" crossOrigin=""/>
      </Head>
      <main>
        <Script
          id="apple-sign-in-js"
          defer={true}
          async={true}
          src={`https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${i18n.language}/appleid.auth.js`}
          onLoad={() => {
            // @ts-ignore
            window.AppleID.auth.init({
              clientId : process.env.NEXT_PUBLIC_APPLE_CLIENT_ID,
              scope : 'name email',
              redirectURI : `${process.env.NEXT_PUBLIC_APP_DOMAIN_URL}/store-feed`,
              state : new ObjectId().toHexString(),
              nonce : new ObjectId().toHexString(),
              usePopup : true
            });
          }}
        />

        <script
          defer
          src='https://static.cloudflareinsights.com/beacon.min.js'
          data-cf-beacon='{"token": "98fc7d44f56c4864982303c37af240a6"}' />

        <Script
          id="facebook-js"
          defer={true}
          async={true}
          crossOrigin="anonymous"
          src={`https://connect.facebook.net/${i18n.language}/sdk.js`}
          onLoad={() => {
            // @ts-ignore
            window.fbAsyncInit = function() {
              // @ts-ignore
              window.FB.init({
                appId            : process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
                autoLogAppEvents : true,
                xfbml            : true,
                version          : 'v12.0'
              });
            };
          }}
        />
        <ErrorBoundary>
          <ConnectedRouter>
            <PersistGate loading={null} persistor={(store as any).__persistor}>
              {AppBody()}
            </PersistGate>
          </ConnectedRouter>
        </ErrorBoundary>
      </main>
    </>
  );
}

export default wrapper.withRedux(appWithTranslation(FitGenieApp));